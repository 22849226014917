.ny-banner {
  position: relative;
  overflow: hidden;
  height: 600px;
}

@media (max-width: 1366px) {
  .ny-banner {
    height: 500px;
  }
}

@media (max-width: 1024px) {
  .ny-banner {
    height: 400px;
  }
}

.ny-banner .bg-img {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-size: cover;
  background-position: center;
}

.ny-banner .bg-img::after {
  content: '';
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  width: 70%;
}

@media (max-width: 1699px) {
  .ny-banner .bg-img::after {
    width: 81.42857%;
  }
}

@media (max-width: 1600px) {
  .ny-banner .bg-img::after {
    width: 92.85714%;
  }
}

@media (max-width: 1366px) {
  .ny-banner .bg-img::after {
    width: 104.28571%;
  }
}

@media (max-width: 1289px) {
  .ny-banner .bg-img::after {
    width: 115.71429%;
  }
}

@media (max-width: 1024px) {
  .ny-banner .bg-img::after {
    width: 127.14286%;
  }
}

@media (max-width: 991px) {
  .ny-banner .bg-img::after {
    width: 138.57143%;
  }
}

@media (max-width: 767px) {
  .ny-banner .bg-img::after {
    width: 150%;
  }
}

.ny-banner .mycontainer {
  height: 100%;
}

.ny-banner .mycontainer .abs {
  height: 100%;
  position: relative;
  color: #fff;
}

.ny-banner .mycontainer .abs > div {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.ny-banner .mycontainer .abs > div h1 {
  line-height: 1.2;
  font-size: 48px;
  margin-bottom: 15px;
}

@media (max-width: 1699px) {
  .ny-banner .mycontainer .abs > div h1 {
    font-size: 44.57143px;
  }
}

@media (max-width: 1600px) {
  .ny-banner .mycontainer .abs > div h1 {
    font-size: 41.14286px;
  }
}

@media (max-width: 1366px) {
  .ny-banner .mycontainer .abs > div h1 {
    font-size: 37.71429px;
  }
}

@media (max-width: 1289px) {
  .ny-banner .mycontainer .abs > div h1 {
    font-size: 34.28571px;
  }
}

@media (max-width: 1024px) {
  .ny-banner .mycontainer .abs > div h1 {
    font-size: 30.85714px;
  }
}

@media (max-width: 991px) {
  .ny-banner .mycontainer .abs > div h1 {
    font-size: 27.42857px;
  }
}

@media (max-width: 767px) {
  .ny-banner .mycontainer .abs > div h1 {
    font-size: 24px;
  }
}

@media (max-width: 1699px) {
  .ny-banner .mycontainer .abs > div h1 {
    margin-bottom: 14.28571px;
  }
}

@media (max-width: 1600px) {
  .ny-banner .mycontainer .abs > div h1 {
    margin-bottom: 13.57143px;
  }
}

@media (max-width: 1366px) {
  .ny-banner .mycontainer .abs > div h1 {
    margin-bottom: 12.85714px;
  }
}

@media (max-width: 1289px) {
  .ny-banner .mycontainer .abs > div h1 {
    margin-bottom: 12.14286px;
  }
}

@media (max-width: 1024px) {
  .ny-banner .mycontainer .abs > div h1 {
    margin-bottom: 11.42857px;
  }
}

@media (max-width: 991px) {
  .ny-banner .mycontainer .abs > div h1 {
    margin-bottom: 10.71429px;
  }
}

@media (max-width: 767px) {
  .ny-banner .mycontainer .abs > div h1 {
    margin-bottom: 10px;
  }
}

.ny-banner .mycontainer .abs > div p {
  max-width: 610px;
  font-size: 24px;
}

@media (max-width: 1699px) {
  .ny-banner .mycontainer .abs > div p {
    font-size: 22.85714px;
  }
}

@media (max-width: 1600px) {
  .ny-banner .mycontainer .abs > div p {
    font-size: 21.71429px;
  }
}

@media (max-width: 1366px) {
  .ny-banner .mycontainer .abs > div p {
    font-size: 20.57143px;
  }
}

@media (max-width: 1289px) {
  .ny-banner .mycontainer .abs > div p {
    font-size: 19.42857px;
  }
}

@media (max-width: 1024px) {
  .ny-banner .mycontainer .abs > div p {
    font-size: 18.28571px;
  }
}

@media (max-width: 991px) {
  .ny-banner .mycontainer .abs > div p {
    font-size: 17.14286px;
  }
}

@media (max-width: 767px) {
  .ny-banner .mycontainer .abs > div p {
    font-size: 16px;
  }
}

.ny-banner .mycontainer .abs > div .link {
  margin-top: 85px;
}

@media (max-width: 1699px) {
  .ny-banner .mycontainer .abs > div .link {
    margin-top: 75.71429px;
  }
}

@media (max-width: 1600px) {
  .ny-banner .mycontainer .abs > div .link {
    margin-top: 66.42857px;
  }
}

@media (max-width: 1366px) {
  .ny-banner .mycontainer .abs > div .link {
    margin-top: 57.14286px;
  }
}

@media (max-width: 1289px) {
  .ny-banner .mycontainer .abs > div .link {
    margin-top: 47.85714px;
  }
}

@media (max-width: 1024px) {
  .ny-banner .mycontainer .abs > div .link {
    margin-top: 38.57143px;
  }
}

@media (max-width: 991px) {
  .ny-banner .mycontainer .abs > div .link {
    margin-top: 29.28571px;
  }
}

@media (max-width: 767px) {
  .ny-banner .mycontainer .abs > div .link {
    margin-top: 20px;
  }
}

.ny-banner.hide-link .mycontainer .abs > div .link {
  display: none;
}
