@import "../../assets/styles/utils";

header {
  .header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;

    &.black-logo {
      .logo {
        // opacity: .8;
        filter: invert(10%);
        filter: progid:DXImageTransform.Microsoft.BasicImage(Invert='10');
      }
    }

    .logo {
      float: left;
      transition: all .5s;
      filter: invert(100%);
      filter: progid:DXImageTransform.Microsoft.BasicImage(Invert='100');
      @include res(margin-left, 200px, 50 / 200);
      @include res(margin-top, 10px, 0 / 10);
      img {
        height: auto;
        @include res(width, 200px, 120 / 200);
      }
    }

    .btn {
      float: right;
      @include res(margin-right, 60px, 10 / 60);
      @include res(margin-top, 45px, 20 / 45);
    }
  }

  .header-aside {
    position: fixed;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, .5);
    transition: transform .6s;
    @include res(height, 100vh, (xs:auto));
    @include res(bottom, 0, (xs:auto !important));
    @include res(top, auto, (xs:0));
    @include res(width, 160px, 50 / 160);

    &.abs {
      position: absolute;
    }

    .openmenu-icon {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
      @include res(background-color, none, (xs:rgba(0, 0, 0, .5)));
      @include res(padding-top, 60px, 20 / 60);
      @include res(padding-bottom, 20px, 20 / 20);

      &:hover {

        .hamburger>div:after,
        .hamburger>view:after {
          transform: scaleX(.5);
        }
      }

      .hamburger {
        margin: 0 auto;
        @include res(width, 48px, 25 / 48);
      }

      .hamburger>div,
      .hamburger>div:after,
      .hamburger>div:before,
      .hamburger>view,
      .hamburger>view:after,
      .hamburger>view:before {
        @include res(width, 48px, 25 / 48);
        @include res(height, 4px, 2 / 4);
      }

      .hamburger>div:before,
      .hamburger>view:before {
        @include res(top, -13px, -8 / -13);
      }

      .hamburger>div:after,
      .hamburger>view:after {
        transform-origin: left center;
        @include res(bottom, -13px, -8 / -13);
      }
    }

    .btm-icon {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 5%;
      color: #fff;
      text-align: center;
      @include res(display, block, (xs:none));

      .iconfont {
        line-height: 1;
        transition: all .3s;
        display: inline-block;
        @include res(font-size, 40px, 26 / 40);
      }

      >div {
        cursor: pointer;
        @include res(padding-top, 30px, 10 / 30);
        @include res(padding-bottom, 30px, 10 / 30);
      }

      .weixin-icon {
        position: relative;
        img{
          @include res(opacity, 0);
          transition: all .3s;
          @include res(transform, translateY(-100px));
          position: absolute;
          bottom: 90%;
          left: 50%;
          margin-left: -45%;
          width: 90%;
        }
        &:hover {
          .iconfont {
            color: rgba(26, 173, 25, 1);
          }
          img{
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      .back-top {
        &:hover {
          .iconfont {
            transform: translateY(-10px);
          }
        }
      }
    }
  }
  .shadenone{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    @include res(width, 65vw, (lg:58vw, mmd:51vw, md:55vw,sm:0vw));
    height: 100vh;
    display: none;
    opacity: 0;
    background-color: #01847F;
  }
  .hidden-nav-c {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 0;
    height: 100vh;
    overflow: hidden;
    transform-origin: left center;
    transition: width .8s $anime-bezier;

    &.active {
      width: 100%;
    }

    .hidden-nav {
      @include res(width, 35vw, (lg:42vw, mmd:49vw, md:45vw,sm:100vw));
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      @extend %clearfix;

      .close-menu {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        @include res(padding-top, 30px, 7 / 30);
        @include res(padding-left, 55px, 11 / 55);

        .iconfont {
          color: #fff;
          @include res(font-size, 50px, 30 / 50);
        }
      }

      .left {
        height: 100%;
        background-color: rgba(18, 17, 17, .95);
        color: #fff;
        overflow-y: auto;
        @include res(float, left, (sm:none));
        @include res(width, 100%, (md:100%, sm:100%));
        @include res(padding-left, 60px, (lg:48px, mmd:69px, md:34px, sm:22px, xs:20px));
        @include res(padding-top, 165px, (lg:130px, mmd:120px, sm:80px));
        @include res(padding-right, 0px, (sm:100px, xs:20px));
        .btn{
          @include res(display, null, (sm:none));
          position: absolute;
          top: 5%;
          right: 10%;
            .iconfont {
              line-height: 0.5;
              @include res(margin-left, 30px, 20 / 30);
              font-size: 20px;
            }
            .common-btn{
              &.light{
                background-color: #ff7800;
                &:hover{
                  background-color: #ff9f22;
                }
              }
            }
        }
        ul {
          li {
            position: relative;
            @include res(width, 220px, (lg:200px, mmd:170px, md:150px, sm:100%));
            @include res(margin-bottom, 50px, 10 / 50);
            @include res(padding-left, 0, (xs:10px));
            @include res(border-bottom, none, (sm:1px solid rgba(255, 255, 255, .1)));
            @include res(padding-bottom, 0, (sm:10px));

            &:hover {
              .sub-nav {
                opacity: 1;
                transform: translateX(0);
                visibility: visible;
              }
            }

            &.active {
              .link-title {
                .iconfont {
                  transform: rotate(90deg);
                }
              }
            }

            &::before {
              content: '';
              background-color: $color-main-light;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translate3d(-120%, -50%, 0) scaleX(0);
              transform-origin: right center;
              transition: all .4s;
              @include res(display, block, (sm:none));
              @include res(width, 258px, (lg:200px, mmd:150px, md:100px));
              @include res(height, 4px);
            }

            .link-title {
              font-weight: normal;
              display: flex;
              align-items: center;
              justify-content: space-between;
              @include res(font-size, 28px, 18 / 28);

              .iconfont {
                transition: all .3s;
                transform-origin: center;
                @include res(padding-left, 15px);
                @include res(padding-right, 15px);
                @include res(display, none, (sm:block));
              }
            }

            .sub-nav {
              position: absolute;
              top: 10%;
              left: 60%;
              white-space: nowrap;
              z-index: 9;
              @include res(margin-top, 0, (sm:10px));
              @include res(transition, all .6s, (sm:none));
              @include res(display, null, (sm:none));
              @include res(position, absolute, (sm:static));
              @include res(opacity, 0, (sm:1));
              @include res(visibility, hidden, (sm:visible));
              @include res(transform, translateX(100px), (sm:translateX(0px)));
              @include res(font-size, 18px, 14 / 18);

              >div {
                @include res(margin-bottom, 25px, 10 / 25);

                a {
                  display: inline-block;
                  position: relative;
                  z-index: 1;
                  @include res(padding-top, 10px, 5 / 10);
                  @include res(padding-bottom, 10px, 5 / 10);
                  @include res(padding-left, 30px, 15 / 30);
                  @include res(padding-right, 30px, 15 / 30);

                  &:hover {
                    &::after {
                      transform: scale(1);
                    }
                  }

                  &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: #ff7800;
                    border-radius: 50px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform-origin: center;
                    z-index: -1;
                    transition: all .5s;
                    transform: scale(0);
                  }
                }
              }
            }
          }
        }
        .ewm {
          @media (max-width: 1367px){
            margin-top: -16%;
          }
          @include res(display, null, (sm:none));
          text-align: right;
          >div {
            bottom: 10px;
            right: 10%;
            @include res(bottom, 10px, (lg:30px, mmd:70px, md:60px));
            white-space: nowrap;

            .item {
              font-size: 14px;
              color: #fff;
              display: inline-block;
              // @include res(margin-left, 20px, 10 / 20);
              @include res(margin-right, 20px, 10 / 20);
              &:not(:first-child) {
                @include res(margin-right, 40px, 30 / 40);
              }

              img {
                display: block;
                margin: 0 auto;
                height: auto;
                @include res(margin-bottom, 10px, 5 / 10);
                @include res(width, 110px, 50 / 110);
              }
            }
          }
        }
      }

      .right {
        display: none;
        height: 100%;
        // background: url(./images/fixed_menu_bg.jpg) no-repeat center;
        background-color: rgba(18,17,17,.95);
        background-size: cover;
        @include res(display, null, (sm:none));
        @include res(float, right);
        // @include res(width, 29%, (md:35%));
        position: absolute;
        @include res(right, 32%, (lg:38%, mmd:38%, md:45%));
        @media (max-width: 1024px){
          top: -28px;
        }
        .btn {
          height: 70vh;
          border-bottom: 1px solid rgba(255, 255, 255, .1);
          position: relative;

          .common-btn {
            position: absolute;
            top: 11%;
            left: 20%;
            white-space: nowrap;
            transform: translate3d(-50%, -50%, 0);
            display: flex;
            align-items: center;
            @extend %clearfix;
            @include res(padding-left, 35px, 25 / 35);
            @include res(padding-right, 30px, 25 / 30);

            .iconfont {
              line-height: 0.6;
              @include res(margin-left, 100px, 50 / 100);
              @include res(font-size, 40px, 30 / 40);
            }
          }
        }

        .ewm {
          height: 30vh;
          position: relative;
          text-align: center;

          >div {
            position: absolute;
            top: 60%;
            left: -100%;
            white-space: nowrap;
            transform: translate3d(-50%, -50%, 0);

            .item {
              font-size: 14px;
              color: #fff;
              display: inline-block;

              &:not(:first-child) {
                @include res(margin-left, 100px, 30 / 100);
              }

              img {
                display: block;
                margin: 0 auto;
                height: auto;
                @include res(margin-bottom, 10px, 5 / 10);
                @include res(width, 120px, 100 / 120);
              }
            }
          }
        }
      }
    }
  }
}

#apply_probation {
  display: none;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  z-index: 1001;
}

#apply_showjoin {
  display: none;
  position: fixed;
  @extend %clearfix;
  top: 20%;
  @media (max-width: 1368px){
    top: 14%;
  }
  @include res(width, 560px, (xs:90%));
  @include res(left, 50%, (xs:5%));
  @include res(margin-left, -280px, (xs:0));
  border-radius: 34px;
  background-color: #fff;
  z-index: 1002;
  overflow: auto;
  @include res(padding-left, 88px, 15 / 88);
  @include res(padding-right, 88px, 15 / 88);
  @include res(padding-bottom, 44px, 18 / 44);
  @include res(padding-top, 56px, 20 / 56);

  .applytitle {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
    @include res(font-size, 16px, 14 / 16);
  }

  .form {
    @include res(max-width, 528px, (mmd:450px, md:90%, xs:100%));
    margin-bottom: 30px;

    form {
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      @include res(display, flex, (xs:block));

      .form-item {
        position: relative;
        overflow: hidden;
        z-index: 1;
        transition: all .1s;
        margin-bottom: 10px;
        @include res(width, 100%);

        &.active {
          border-bottom-color: #01847F;

          label {
            @include res(font-size, 14px, 12 / 14);
            @include res(top, -10px, -8 / -10);
          }
        }

        label {
          @include res(font-size, 16px, 14 / 16);
          z-index: -1;
          transition: all .3s;
          color: #666;
          margin-right: 5px;

          span {
            color: #666;
          }
        }

        input {
          width: 80%;
          margin-top: 10px;
          background: none;
          border: none;
          outline: none;
          color: #666;
          padding: 0 12px;
          background-color: #f5f2f2;
          @include res(height, 40px, 30 / 40);
        }
      }
    }

    ::-webkit-input-placeholder {
      /* WebKit browsers，webkit内核浏览器 */
      color: #ccc;
    }

    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #ccc;
    }

    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #ccc;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #ccc;
    }

  }

  .apply_btn {
    @extend %clearfix;
    float: right;

    .applybtn1 {
      float: left;
      // margin-right: 10px;
      text-align: center;
      @include res(width, 120px, (xs:90px));
      @include res(height, 40px, (xs:40px));
      line-height: 40px;
      cursor: pointer;
    }

    .applybtn2 {
      float: left;
      @include res(width, 120px, (xs:90px));
      @include res(height, 40px, (xs:40px));
      line-height: 40px;
      background: #ff7800;
      text-align: center;
      color: #fff;
      cursor: pointer;
      border-radius: 8px;
    }
  }
}