@import "../../assets/styles/utils";

.ny-banner{
  position: relative;
  overflow: hidden;
  @include res(height,600px,(mmmd:500px,md:400px));
  .bg-img{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-size: cover;
    background-position: center;
    &::after{
      content: '';
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(left, rgba(0,0,0,.7),rgba(0,0,0,0));
      @include res(width,70%, 150 / 70);
    }
  }
  .mycontainer{
    height: 100%;
    .abs{
      height: 100%;
      position: relative;
      color: #fff;
      >div{
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        h1{
          line-height: 1.2;
          @include res(font-size,48px, 24 / 48);
          @include res(margin-bottom,15px, 10 / 15);
        }
        p{
          max-width: 610px;
          @include res(font-size,24px, 16 / 24);
        }
        .link{
          @include res(margin-top,85px, 20 / 85);
        }
      }
    }
  }
  &.hide-link{
    .mycontainer .abs>div .link{
      display: none;
    }
  }
}