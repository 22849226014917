@font-face {
  font-family: "iconfont"; /* Project id 3904812 */
  src: url('iconfont.woff2?t=1677203304624') format('woff2'),
       url('iconfont.woff?t=1677203304624') format('woff'),
       url('iconfont.ttf?t=1677203304624') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-jiugongge:before {
  content: "\e608";
}

.icon-jiantou_qiehuanyou:before {
  content: "\e60d";
}

.icon-duihao:before {
  content: "\e633";
}

.icon-qianbi:before {
  content: "\e604";
}

.icon-weixin:before {
  content: "\e600";
}

.icon-hanbaocaidan:before {
  content: "\e661";
}

.icon-xialajiantouxiao:before {
  content: "\e87e";
}

.icon-yuyinguanbi:before {
  content: "\e903";
}

.icon-dui:before {
  content: "\e623";
}

.icon-jiantou_qiehuanzuo_o:before {
  content: "\eb90";
}

.icon-fanhuidingbu:before {
  content: "\e60a";
}

