@import "../../assets/styles/utils";

footer{
  background-color: #000;
  color: #fff;
  @include res(padding-top,110px, 30 / 110);
  @include res(padding-left,160px, 20 / 160);
  @include res(padding-right,160px, 20 / 160);
  @media screen and(max-width:1366px) {
    @include res(padding-top,70px, 30 / 70);
  }
  .footer,.copyright{
    max-width: 1600px;
    margin: 0 auto;
    font-size: 16px;
  }
  .footer{
    @extend %clearfix;
    @include res(padding-bottom,60px, 20 / 60);
    @media screen and(max-width:1366px) {
      @include res(padding-bottom,40px, 30 / 40);
    }
    .left{
      @include res(text-align,null,(md:center));
      @include res(float,left,(md:none));
      // @include res(margin-bottom,0,(md:50px));
      .footer-logo{
        filter: invert(100%);
        filter: progid:DXImageTransform.Microsoft.BasicImage(Invert='100');
        @include res(margin-bottom,50px, 20 / 50);
        img{
          height: auto;
          @include res(width,200px);
        }
      }
      .footer-info{
        @include res(margin-bottom,50px, 20 / 50);
        p{
          @include res(margin-bottom,10px, 5 / 10);
        }
      }
      .footer-ewm{
        text-align: center;
        @extend %clearfix;
        .item{
          font-size: 14px;
          display: inline-block;
          @include res(float,left,(md:none));
          &:not(:last-child){
            @include res(margin-right,60px, 20 / 60);
          }
          img{
            display: block;
            height: auto;
            margin: 0 auto;
            @include res(margin-bottom,15px, 10 / 15);
            @include res(width,120px);
          }
        }
      }
    }
    .right{
      @include res(float,right,(md:none));
      .footer-nav{
        @include res(display,null,(md:none));
        ul{
          @extend %clearfix;
          li{
            @include res(float,left);
            &:not(:first-child){
              @include res(margin-left,80px, 30 / 80);
            }
            a{
              display: block;
              transition: all .3s;
              @include res(margin-bottom,15px, 10 / 15);
              &.title-link{
                @include res(font-size, 20px, 18 / 20);
              }
              &:not(.title-link){
                color: rgba(255,255,255,.7);
                &:hover{
                  color: rgba(255,255,255,1);
                }
              }
            }
          }
        }
      }
      .btn{
        @include res(text-align,right,(md:center));
        @include res(margin-top,100px, 30 / 100);
        .common-btn{
          @extend %clearfix;
          @include res(padding-left,35px, 25 / 35);
          @include res(padding-right,30px, 25 / 30);
          .iconfont{
            line-height: 0.6;
            float: right;
            @include res(transform,translateY(5px),(md:translateY(7px)));
            @include res(margin-left,180px, 80 / 180);
            @include res(font-size,40px, 30 / 40);
          }
        }
      }
    }
  }
  .copyright{
    border-top: 1px solid rgba(255,255,255,.1);
    font-size: 12px;
    @include res(text-align,null,(xs:center));
    @extend %clearfix;
    @include res(padding-top,25px, 10 / 25);
    @include res(padding-bottom,25px, 10 / 25);
    .left{
      @include res(float,left,(xs:none));
      a{
        color: rgba(255,255,255,.7);
        transition: all .3s;
        &:not(:last-child){
          @include res(margin-right,30px, 5 / 30);
        }
        &:hover{
          color: rgba(255,255,255,1);
        }
      }
    }
    .right{
      color: rgba(255,255,255,.5);
      @include res(float,right,(xs:none));
      a{
        transition: all .3s;
        @include res(margin-left,10px, 5 / 10);
        &:hover{
          color: rgba(255,255,255,1);
        }
      }
    }
  }
}