@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/dist/css/swiper.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import './animate.css';
@import "../fonts/iconfont.css";


*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}

body {
  font-family: "PingFang SC","Microsoft YaHei";
  line-height: 1.6;
  position: relative;
  color: #221f1f;
  background-color: #fff;
  font-weight: normal;
  @include res(font-size, 14px);
}
.comp-root{
  overflow-x: hidden;
}


//主体内容部分宽度
.pdl{
  @include res(padding-left,160px,50 / 160);
  @media screen and (max-width:767px) {
    padding-left: 0;
  }
}
.mycontainer{
  max-width: 1520px;
  margin: 0 auto;
  box-sizing: content-box;
  @include res(padding-left,120px,20 / 120);
  @include res(padding-right,120px,20 / 120);
}

.common-btn{
  display: inline-block;
  border-radius: 50px;
  background-color: $color-main;
  color: #fff;
  transition: all .3s;
  @include res(font-size, 16px, 14 / 16);
  @include res(padding-top,10px, 5 / 10);
  @include res(padding-bottom,10px, 5 / 10);
  @include res(padding-left,50px, 25 / 50);
  @include res(padding-right,50px, 25 / 50);
  &:hover{
    background-color: $color-main-light;
  }
  &.light{
    background-color: $color-main-light;
    &:hover{
      background-color: $color-main;
    }
  }
  &.gray{
    background-color: #3d3a39;
    &:hover{
      background-color: $color-main-light;
    }
  }
}

.haszw-img{
  position: relative;
  overflow: hidden;
  img{
    display: block;
    width: 100%;
    height: auto;
    opacity: 0;
  }
  b,a{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    transition: all .3s;
  }
}
.fancybox-bg{
  background: rgba(0,0,0,1);
}
.common-ny-nav{
  position: relative;
  .ny-nav{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 10;
    box-shadow: 0px 0px 10px rgba(0,0,0,.1);
    transition: all .3s;
    @include res(padding-top,20px, 9 / 20);
    @include res(padding-bottom,20px, 8 / 20);
    .mycontainer{
      @include res(line-height, 48px, 28 / 48);
      @extend %clearfix;
      @media screen and (max-width:767px) {
        padding-right: 10px;
      }
      .crumbs{
        white-space: nowrap;
        @include res(font-size,16px, 12 / 16);
        @include res(float,right,(md:none));
        @include res(margin-bottom,0,(md:20px,sm:15px,xs:7px));
        @include res(line-height,null,(md:1.2));
        a{
          transition: all .3s;
          &:hover{
            color: $color-main
          }
          &:last-child{
            color: $color-main
          }
        }
        .iconfont{
          @include res(font-size,16px, 12 / 16);
          @include res(margin-left,5px);
        }
      }
      .nav{
        @include res(float,left,(md:none));
        ul{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          overflow-x: auto;
          @extend %clearfix;
          li{
            flex-shrink: 0;
            @include res(float,left);
            &:not(:last-child){
              @include res(margin-right,20px, 5 / 20);
            }
            &.active{
              a{
                border: 1px solid $color-main;
                background-color: $color-main;
                color: #fff;
              }
            }
            a{
              border: 1px solid #231f20;
              transition: all .3s;
              display: inline-block;
              border-radius: 50px;
              @include res(font-size,16px, 14 / 16);
              @include res(padding-left,30px, 15 / 30);
              @include res(padding-right,30px, 15 / 30);
              &:hover{
                border: 1px solid $color-main;
                color: $color-main;
              }
              &.active{
                border: 1px solid $color-main;
                background-color: $color-main;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  &.fixed{
    .ny-nav{
      position: fixed;
      @include res(padding-left,null,(xs:40px));
    }
  }
}
.common-title{
  @include res(font-size,32px, 22 / 32);
  @include res(padding-top,110px, 40 / 110);
  @include res(padding-bottom,70px, 20 / 70);
  &.white{
    color: #fff;
  }
}

// 产品公共css
.pro-common-intro{
  background-color: $color-main-bg;
  position: relative;
  .bg-canvas{
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    filter: brightness(90%);
    canvas{
      width: 100%;
      height: 100%;
      pointer-events: none;
      touch-callout: none;
      transform: translateY(40%);
    }
  }
  .cont{
    position: relative;
    z-index: 1;
    @extend %clearfix;
    .left{
      box-sizing: border-box;
      @include res(float,left,(sm:none));
      @include res(width,55%,(sm:100%));
      @include res(padding-left,120px,20 / 120);
      @include res(padding-right,160px,20 / 160);
      @include res(padding-bottom,250px, 100 / 250);
      @media screen and (max-width:1024px) {
        @include res(padding-bottom,120px, 50 / 120);
      }
      .desc{
        p{
          &:not(:last-child){
            @include res(margin-bottom,25px, 15 / 25);
          }
        }
      }
    }
    .right{
      @include res(display,block,(sm:none));
      @include res(float,right,(sm:none));
      @include res(width,45%,(sm:100%));
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}

.pro-common-function{
  position: relative;
  @include res(padding-bottom,120px, 40 / 120);
  .bg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.75);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
  .mycontainer{
    position: relative;
    z-index: 1;
    .funtion-list{
      ul{
        @include res(width,calc(100% + 30px),(xs:100%));
        @extend %clearfix;
        li{
          border-radius: 25px;
          overflow: hidden;
          @include res(float,left);
          @include res(width,calc(25% - 30px),(md:calc(50% - 30px),xs:100%));
          @include res(margin-right,30px,(xs:0));
          @include res(margin-bottom, 50px, 15 / 50);
          &:nth-child(2){
            animation-delay: .1s;
          }
          &:nth-child(3){
            animation-delay: .2s;
          }
          &:nth-child(4){
            animation-delay: .3s;
          }
          .item-img{
            position: relative;
            overflow: hidden;
            @include res(height,365px,(lg:300px,mmmd:200px,mmd:200px));
            b{
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background-position: center;
              background-size: cover;
              transition: all .4s;
            }
          }
          .item-text{
            background-color: #fff;
            box-sizing: border-box;
            @include res(padding-left,40px, 20 / 40);
            @include res(padding-right,40px, 20 / 40);
            @include res(padding-top,25px, 15 / 25);
            @include res(padding-bottom,15px);
            @include res(height,250px,(lg:270px,mmmd:240px,md:200px,sm:230px,xs:180px));
            h3{
              line-height: 1.2;
              @include res(font-size, 26px, 20 / 26);
              @include res(margin-bottom,25px, 15 / 25);
            }
          }
          &:hover{
            .item-img b{
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}

.pro-common-advantage{
  overflow: hidden;
  .advantage-img{
    ul{
      li{
        position: relative;
        @extend %clearfix;
        @include res(padding-top,80px, 40 / 80);
        @include res(padding-bottom,80px, 40 / 80);
        &::before{
          content: '';
          display: block;
          height: 100%;
          width: 100%;
          background-color: #f3f3f3;
          position: absolute;
          bottom: 0;
          right: auto;
          z-index: -1;
          @include res(left,62%,(xs:75%));
        }
        &:first-child{
          @include res(margin-top,-80px, -40 / -80);
          &::before{
            height: 150%;
          }
        }
        .item-img{
          overflow: hidden;
          border-radius: 25px;
          @include res(float,right,(xs:none));
          @include res(width,51.3%,(xs:100%));
          img{
            display: block;
            border-radius: 25px;
            width: 100%;
            height: auto;
            transition: all .4s;
          }
          &:hover{
            img{
              transform: scale(1.1);
            }
          }
        }
        .item-itext{
          left: 0;
          right: auto;
          top: 50%;
          @include res(position,absolute,(xs:static));
          @include res(transform,translateY(-50%),(xs:translateY(0%)));
          @include res(width,41.1%,(xs:100%));
          @include res(margin-top,0,(xs:20px));
          h3{
            @include res(font-size, 20px, 18 / 20);
            @include res(margin-bottom,45px, 15 / 45);
          }
          .desc{
            p{
              &:not(:last-child){
                @include res(margin-bottom,25px, 15 / 25);
              }
            }
          }
        }
        &:nth-child(even){
          &::before{
            left: auto;
            @include res(right,62%,(xs:75%));
          }
          .item-img{
            @include res(float,left,(xs:none));
          }
          .item-itext{
            left: auto;
            right: 0;
          }
        }
      }
    }
  }
  .advantage-text{
    @include res(padding-bottom, 80px, 30 / 80);
    ul{
      @extend %clearfix;
      @include res(width,calc(100% + 40px),(xs:100%));
      li{
        background-color: #f6f5f5;
        border-radius: 24px;
        position: relative;
        transition: box-shadow .4s;
        @include res(float,left,(xs:none));
        @include res(width,calc(50% - 40px),(xs:100%));
        @include res(margin-right,40px,(xs:0));
        @include res(margin-bottom,40px,(xs:20px));
        @include res(height,180px,(mmmd:160px,xs:160px));
        &:hover{
          box-shadow: 0px 0px 20px rgba(0,0,0,.2);
          .item-img img{
            transform: scale(1.2);
          }
        }
        .item-img{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          @include res(left,60px, 20 / 60);
          img{
            display: block;
            height: auto;
            transform-origin: center;
            transition: all .3s;
            @include res(width, 60px, 40 / 60);
          }
        }
        .item-text{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          @include res(left,150px, 80 / 150);
          @include res(width, 70%,(md:65%,sm:60%));
          h4{
            @include res(font-size,20px, 18 / 20);
            @include res(margin-bottom,20px, 10 / 20);
          }
        }
      }
    }
  }
}
.pro-common-honor{
  background-color: $color-main-bg;
  @include res(padding-top,120px, 20 / 120);
  @include res(padding-bottom,100px, 40 / 100);
  .swiper{
    overflow: hidden;
    position: relative;
    @include res(padding,25px, 15 / 25);
    @include res(padding-bottom,90px, 50 / 90);
    .swiper-slide{
      .item{
        text-align: center;
        .item-img{
          // background-color: #fff;
          border-radius: 14px;
          position: relative;
          cursor: pointer;
          transition: box-shadow .4s;
          @include res(height,400px,(lg:300px,xs:350px));
          @include res(margin-bottom,30px, 15 / 30);
          &:hover{
            // box-shadow: 0px 0px 20px rgba(0,0,0,.2);
            img{
              transform: translateX(-50%) translateY(-50%) scale(1.05);
              // transform: translateX(-50%)  scale(1.05);
              box-shadow: 0px 0px 20px rgba(0,0,0,.2);
            }
          }
          img{
            display: block;
            max-width: 90%;
            max-height: 90%;
            width: auto;
            height: auto;
            position: absolute;
            border-radius: 16px;
            top: 50%;
            // top: 10%;
            left: 50%;
            // transform: translateX(-50%);
            transform: translateX(-50%) translateY(-50%);
            transition: all .4s;
          }
        }
        .item-t{
          // @include res(font-size, 20px, 16 / 20);
          @include res(font-size, 14px);
        }
      }
      &:nth-child(2){
        .item{
          animation-delay: .2s;
        }
      }
      &:nth-child(3){
        .item{
          animation-delay: .4s;
        }
      }
      &:nth-child(4){
        .item{
          animation-delay: .6s;
        }
      }
    }
    .swiper-pagination{
      .swiper-pagination-bullet{
        background-color: #858585;
        opacity: 1;
        @include res(width, 12px, 8 / 12);
        @include res(height, 12px, 8 / 12);
        @include res(margin-left,10px, 3 / 10);
        @include res(margin-right,10px, 3 / 10);
        &.swiper-pagination-bullet-active{
          background-color: $color-main;
        }
      }
    }
  }
}

//解决方案公共css
.bg-gray{
  background-color: $color-main-bg;
}
.solution-common-points{
  background-color: #f6f6f6;
  position: relative;
  @include res(padding-bottom,120px, 40 / 120);
  .bg-canvas{
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    filter: brightness(90%);
    canvas{
      width: 100%;
      height: 100%;
      pointer-events: none;
      touch-callout: none;
      transform: translateY(40%);
    }
  }
  .mycontainer{
    position: relative;
    z-index: 1;
    .cont{
      @extend %clearfix;
      .left{
        @include res(float,left,(sm:none));
        @include res(width,40%,(sm:100%));
        @include res(padding-top,60px, 0 / 60);
        .desc{
          line-height: 1.7;
          li{
            background-image: url(../../assets/images/p_icon.png);
            background-repeat: no-repeat;
            background-position: 0px 0px;
            @include res(padding-left,35px);
            &:not(:last-child){
              @include res(margin-bottom,20px, 10 / 20);
            }
            p{
              &:not(:last-child){
                @include res(margin-bottom,20px, 10 / 20);
              }
            }
          }
        }
      }
      .right{
        overflow: hidden;
        @include res(display,block,(sm:none));
        @include res(float,right);
        @include res(width,50%);
        &:hover{
          img{
            transform: scale(1.05);
          }
        }
        img{
          display: block;
          width: 100%;
          height: auto;
          transition: all .4s;
        }
      }
    }
  }
}
.solution-common-intro{
  position: relative;
  @include res(padding-bottom,120px, 40 / 120);
  .bg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.75);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
  .mycontainer{
    position: relative;
    z-index: 1;
    .cont{
      border-radius: 25px;
      background-color: #e8e8e8;
      overflow: hidden;
    }
  }
}
.solution-common-case{
  position: relative;
  @include res(padding-bottom,120px, 40 / 120);
  &::after{
    content: '';
    display: block;
    height: 100%;
    background-color: #f3f2f2;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    @include res(width,35%);
  }
  .cont{
    position: relative;
    background-color: #fff;
    border-radius: 25px;
    overflow: hidden;
    box-shadow: 0px 10px 15px rgba(0,0,0,.05);
    @extend %clearfix;
    .bg{
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      @include res(left,20%);
      @include res(display,block,(sm:none));
    }
    .left{
      position: relative;
      z-index: 1;
      overflow: hidden;
      background-color: #f3f2f2;
      @include res(float,left,(sm:none));
      @include res(width,42%,(sm:100%));
      &:hover{
        img{
          transform: scale(1.05);
        }
      }
      img{
        display: block;
        width: 100%;
        height: auto;
        transition: all .4s;
      }
    }
    .right{
      position: relative;
      z-index: 1;
      box-sizing: border-box;
      @include res(float,right,(sm:none));
      @include res(width,58%,(sm:100%));
      @include res(padding-left,125px, 20 / 125);
      @include res(padding-right,125px, 20 / 125);
      @include res(padding-top,110px, 20 / 110);
      @include res(padding-bottom,50px, 20 / 50);
      p{
        &:not(:last-child){
          @include res(margin-bottom,20px, 10 / 20);
        }
      }
    }
  }
}